import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { PageHeaderWithBreadcrumbs } from "../components/pageHeaderWithBreadcrumbs";
import "@fortawesome/fontawesome-free/css/all.min.css";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const numberOfFaqItems = 9;

const FAQ: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();
  const faqItems = Array.from({ length: numberOfFaqItems }, (_, i) => i + 1);

  return (
    <Layout
      seoTitle={t("faq.title")}
      seoDescription={t("faq.seoDescription")}
      location={location}
    >
      <div className="container">
        <PageHeaderWithBreadcrumbs
          currentBreadcrumbText={t("faq.title")}
          title={t("faq.pageTitle")}
        />

        <section className="pb-5">
          {faqItems.map((faqItemNumber) => (
            <div className="card faq-card">
              <div
                className="card-header bg-white"
                id={`faqTitle${faqItemNumber}`}
              >
                <a
                  href={`#faqCollapse${faqItemNumber}`}
                  className="d-flex align-items-center collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <h6 className="mb-0">
                    {t(`faq.item${faqItemNumber}.question`)}
                  </h6>{" "}
                  <i className="far fa-plus-square ml-auto"></i>
                </a>
              </div>
              <div
                id={`faqCollapse${faqItemNumber}`}
                className="collapse"
                aria-labelledby={`faqTitle${faqItemNumber}`}
              >
                <div className="card-body">
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t(`faq.item${faqItemNumber}.answer`),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </Layout>
  );
};

export default FAQ;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
