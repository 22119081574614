import * as React from "react";
import { useTranslation } from "react-i18next";
import { TranslatedLink } from "gatsby-plugin-translate-urls";

interface Props {
  title: string;
  currentBreadcrumbText: string;
}

export function PageHeaderWithBreadcrumbs(props: Props) {
  const { t } = useTranslation();

  return (
    <section className="page-header">
      <h1>{props.title}</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb ins-breadcrumb">
          <li className="breadcrumb-item">
            <TranslatedLink to="/">
              {t("pageHeaderWithBreadcrumbs.homePage")}
            </TranslatedLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {props.currentBreadcrumbText}
          </li>
        </ol>
      </nav>
    </section>
  );
}
